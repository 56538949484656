import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Entity } from '@ui-kit/main-api-types';
import { UserState } from '@ui-kit/main-frontend-ui-kit/dist/src/utils/types';

import { refreshPoll } from '@store/poll/actions';
import { ContentTypeName } from '@utils/types';

type IUseUpdateContent = {
	content: Entity[];
	locale: string;
	userStore: UserState;
	setContent: Dispatch<SetStateAction<Entity[]>>;
};

export const useUpdateContent = ({
	content,
	locale,
	userStore,
	setContent,
}: IUseUpdateContent) => {
	const [isPollsLoaded, setIsPollsLoaded] = useState(false);
	const { loginChecked, hasUserLoggedIn } = userStore || {};

	const refetchPolls = async () => {
		const pollIds = content
			?.filter(item => item?.__typename === ContentTypeName.CustomPoll)
			?.map(poll => poll?.id);

		const updatedPolls = await Promise.all(
			pollIds?.map(id => refreshPoll(id, locale)),
		);
		setContent(prev =>
			prev?.map(item => {
				if (item?.__typename === ContentTypeName.CustomPoll) {
					const updatedPoll = updatedPolls?.find(poll => poll?.id === item?.id);
					return updatedPoll ? { ...item, ...updatedPoll } : item;
				}
				return item;
			}),
		);
		setIsPollsLoaded(true);
	};

	useEffect(() => {
		if (loginChecked) {
			refetchPolls();
		}
	}, [loginChecked, hasUserLoggedIn]);

	return { isPollsLoaded };
};
